import api from '@/utils/api'

export default {
    getTeams(page, size, query = {}) {
        let filter = ''
        if (Object.values(query).length > 0) {
            filter = Object.values(query).reduce((total, value) => total + `, ${value}`)
        }
        filter += '"active": true'
        const range = '?range=' + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get('teams' + (page ? range : '') + (filter ? `&filter=${encodeURIComponent('{' + filter + '}')}` : ''))
    },

    getTeam(id, phase = {}) {
        let filter = (phase === 'cup' ? '?isCup=true&' : '?')
        filter += (phase && phase !== 'cup' ? `phase=${phase}&isCup=false` : '')
        return api.get(`teams/${id}` + filter)
    },

    getRankingByTeam(id, phase = {}) {
        let filter = (phase === 'cup' ? '?isCup=true&' : '?')
        filter += (phase && phase !== 'cup' ? `phase=${phase}` : '')
        return api.get(`teams/${id}/ranking` + filter)
    },

    register(data) {
        return api.post('team-applications', data)
    }
}
